import React from 'react'
import FreeMembers from '../../../Components/members/freeMembers/FreeMembers'

function PremiumMembersPage() {
    return (
        <>
            <FreeMembers />
        </>
    )
}

export default PremiumMembersPage