import React from 'react'
import UnAppRovedProfilePics from '../../Components/members/unApprovedProfilePics/UnAppRovedProfilePics'

function UnApprovedProfilePicsPages() {
    return (
        <>
            <UnAppRovedProfilePics />
        </>
    )
}

export default UnApprovedProfilePicsPages