import React from 'react'
import WebsiteSetupFooter from '../../../Components/websiteSetup/websiteSetupFooter/WebsiteSetupFooter'

function WebsiteSetupFooterPage() {
    return (
        <>
            <WebsiteSetupFooter />
        </>
    )
}

export default WebsiteSetupFooterPage