import AllStaffs from "../../Components/allStaffs/AllStaffs";


function AllStaffsPage() {
  return (
    <>
      <AllStaffs/>
    </>
  )
}
export default AllStaffsPage;