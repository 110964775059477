import WalletTranstionHistory from "../../../Components/wallet/wallet-transition-history/WalletTranstionHistory"


function WalletTransitionHistoryPage() {
    return (
        <>
        <WalletTranstionHistory/>
        </>
    )
}
export default WalletTransitionHistoryPage