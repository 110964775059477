import React from 'react'
import ProfileSections from '../../../Components/members/profileSections/ProfileSections'

function ProfileSectionSPage() {
    return (
        <>
            <ProfileSections />
        </>
    )
}
export default ProfileSectionSPage