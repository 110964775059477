import Category from "../../../Components/blog/categoris/Catagory"


function CategoryPage() {
    return (
        <>
        <Category/>
        </>
    )
}
export default CategoryPage