import React from 'react'
import WebsiteSetupHeader from '../../../Components/websiteSetup/websiteSetupHeader/WebsiteSetupHeader';

function WebsiteSetupHeaderPage() {
  return (
    <>
      <WebsiteSetupHeader />
    </>
  )
}
export default WebsiteSetupHeaderPage;